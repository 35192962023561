<template>
	<div class="bg-white p-4 rounded border shadow-sm h-100">
		<b-overlay :show="loading" variant="light" spinner-type="grow">

			<section class="rounded bg-light-app px-4 pt-4 pb-2 mb-4 position-relative">
				<h1 class="display-4">
					Payment Reconciliation Report
					<small class="text-muted">Reconcile monthly payments</small>
				</h1>
			</section>

			<div class="shadow p-4">

				<error-alert :error="error" class="m-4"/>

				<b-form inline>

					<b-form-select v-model="month" :options="months" class="mr-4"></b-form-select>

					<b-form-select v-model="year" :options="years" class="mr-4"></b-form-select>

					<div class="btn-group border rounded mr-4" role="group">
						<button type="button"
						        class="btn btn-light shadow-none"
						        :class="{ active: filter === 'all'}"
						        @click="filter = 'all'"
						>All
						</button>
						<button type="button"
						        class="btn btn-light shadow-none"
						        :class="{ active: filter === 'over'}"
						        @click="filter = 'over'"
						>Over-paid
						</button>
						<button type="button"
						        class="btn btn-light shadow-none"
						        :class="{ active: filter === 'under'}"
						        @click="filter = 'under'"
						>Under-paid
						</button>
						<button type="button"
						        class="btn btn-light shadow-none"
						        :class="{ active: filter === 'exact'}"
						        @click="filter = 'exact'"
						>Exact
						</button>
					</div>

					<b-button variant="primary" @click="filterStudentPayments" class="mr-4">Apply Filters</b-button>

					<span>Filter total: {{ report.length }}</span>

				</b-form>

				<b-table
						hover
						show-empty
						:items="report"
						:fields="reportFields"
						class="mt-4"
				>

					<template #cell(expected)="data">
						&pound;{{ data.value.toFixed ( 2 ) }}
					</template>

					<template #cell(actual)="data">
						&pound;{{ data.value.toFixed ( 2 ) }}
					</template>

					<template #cell(difference)="data">
						<span v-if="data.value >= 0" class="text-success">&pound;{{ data.value.toFixed ( 2 ) }}</span>
						<span v-else class="text-danger">&pound;{{ data.value.toFixed ( 2 ) }}</span>
					</template>

				</b-table>

			</div>

		</b-overlay>
	</div>
</template>

<script>

import { DateTime } from 'luxon';

export default {

    name      : 'ReportPaymentReconciliation',
    mixins    : [],
    components: {},

    props: {},

    data () {
        return {

            loading: true,
            error  : false,

            students: [],
            months  : [
                { value: 1, text: 'January' },
                { value: 2, text: 'February' },
                { value: 3, text: 'March' },
                { value: 4, text: 'April' },
                { value: 5, text: 'May' },
                { value: 6, text: 'June' },
                { value: 7, text: 'July' },
                { value: 8, text: 'August' },
                { value: 9, text: 'September' },
                { value: 10, text: 'October' },
                { value: 11, text: 'November' },
                { value: 12, text: 'December' }
            ],
            years   : [],

            month : null,
            year  : null,
            filter: 'all',

            report      : [],
            reportFields: [
                { key: 'displayName', label: 'Student', sortable: true },
                { key: 'identifier', label: 'Identifier', sortable: true },
                { key: 'standingOrderDayOfMonth', label: 'SO Day', sortable: true },
                { key: 'expected', label: 'Expected', sortable: true },
                { key: 'actual', label: 'Actual', sortable: true },
                { key: 'difference', label: 'Difference', sortable: true }
            ]

        };
    },

    computed: {},

    methods: {

        filterStudentPayments () {

            this.report = this
                .students
                .map ( student => {

                    const reportItem = {
                        displayName            : student.displayName,
                        identifier             : `CEMAA${ student.identifier }`,
                        standingOrderDayOfMonth: student.standingOrderDayOfMonth,
                        expected               : student.report.expected,
                        classes                : student.classes
                    };

                    // Filter payments that match date criteria
                    reportItem.payments = student.payments.filter ( x => {
                        const dt = DateTime.fromISO ( x.paymentDate );
                        return dt.month === this.month && dt.year === this.year;
                    } );

                    // Sum up actual payments
                    reportItem.actual = ( reportItem.payments.reduce ( ( n, { amount } ) => n + amount, 0 ) );

                    // Calculate difference expected vs actual
                    reportItem.difference = ( reportItem.actual - reportItem.expected );

                    return reportItem;

                } )
                .filter ( student => {

                    if ( this.filter === 'all' ) {
                        return true;
                    } else if ( this.filter === 'under' && student.difference < 0 ) {
                        return true;
                    } else if ( this.filter === 'over' && student.difference > 0 ) {
                        return true;
                    } else if ( this.filter === 'exact' && student.difference === 0 ) {
                        return true;
                    }

                    return false;

                } )
            ;

        }

    },

    async mounted () {

        // Get students
        let response = await this.$http.get (
            `${ this.config.apiRoot }/students`,
            {
                responseType   : 'json',
                withCredentials: false // CORS dev server
            }
        );

        // Calculate expected monthly payment
        this.students = response.data.records.map ( student => {

            const expected = ( student.classes.reduce ( ( n, { monthlyFee } ) => n + monthlyFee, 0 ) );
            student.report = { expected };

            student.displayName = `${ student.firstName } ${ student.lastName }`.trim ();

            return student;

        } );

        // Set current month and year
        const dt = DateTime.local ();
        this.month = dt.month;
        this.year = dt.year;

        // Create year list
        for ( let year = this.year; year >= 2021; year-- ) {
            this.years.push ( { value: year, text: year } );
        }

        // Apply initial filters
        this.filterStudentPayments ();

        this.loading = false;

    }

};

</script>

<style scoped lang="scss">

.btn-group-toggle {

	label.btn {
		box-shadow: none;
	}

}

</style>
